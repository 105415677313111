.project > img {
  display: block;
  max-width: 80vw;
  max-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.project > div.youtube__container {
  display: block;
  text-align: center;
}
