.homepage {
  margin-left: 100px;
  margin-right: 100px;
}
.homepage-project {
  display: inline-block;
}
.hompage > .row {
  min-height: 300px;
  margin-bottom: 100px;
}
.homepage > .row > .homepage-project > a > .preview {
  min-height: 300px;
  margin-bottom: 100px;
  width: 30%;
  height: 80%;
}
