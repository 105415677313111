.header {
  width: 100%
}
.header > ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 100px 0 100px;
  background-color: white;
}
.header > ul > li {
  border-left: 1px solid #D3D3D3;
  flex-grow: 1;
  list-style-type: none;
  text-align: center;
  border-bottom: 1px solid #D3D3D3;
}
.header > ul > li:last-child {
  border-right: 1px solid #D3D3D3;
}
.header > ul > li > a {
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: Helvetica;
  padding: 12px;
}

.link__astra {
  color: #FF8800;
}

.link__archive {
  color: #FFFF44;
}

.link__info {
  color: #00FF95;
}

.link__social {
  color: #00FFFF;
}

.link__contact {
  color: #EE00EE;
}
